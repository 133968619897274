// export default Register;


import '../App.css';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { SignupSchema } from '../schemas';
import state from '../state';
import Modal from 'react-modal'
import { NavLink } from 'react-router-dom';

function Register() {

   const [isLoader, setIsLoader] = useState(false);
   const [openLoadermodal, setopenLoadermodal] = useState(false)
   
   const [isSubmit, setIsSubmit] = useState(false);
   const [openmodal, setopenmodal] = useState(false)
   const [dropdown1,Setdropdown1] = useState(false)
   const [dropdown2,Setdropdown2] = useState(false)
   const [dropdown3,Setdropdown3] = useState(false)

   // const [isDropDownVisiable, setIsDropDownVisible] = useState(false);
   // const [itemList, setItemList] = useState([
   //    {
   //       name: "Cataract",
   //       value: "Cataract"
   //    },
   //    {
   //       name: "Retina",
   //       value: "Retina"
   //    },
   //    {
   //       name: "Other",
   //       value: "Other"
   //    }
   // ])

   // const [selectedItem, setSelectedItem] = useState(null);

   const initialValues = {
      first_name: "",
      last_name: "",
      npi_number: "",
      state_licence_no: "",
      email_add:"",
      facility_name: "",
      street: "",
      suite: "",
      city: "",
      zipcode: "",
      state: "",
      state_license: "",
      sub_speciality: "",
      check: false
   };

   const handleClose = () => {
      window.location.reload();
      setopenmodal(false)
   }

   const { values, errors, handleBlur, touched, handleChange, handleSubmit } = useFormik({
      initialValues: initialValues,
      validationSchema: SignupSchema,
      onSubmit: (values, action) => {
         // setIsLoader(true);
         // setopenLoadermodal(true);

         window.dataLayer = window.dataLayer || [];
         window.dataLayer.push({
                  'event' : 'starter_program_contact',
         });

         
         setIsSubmit(true);
         setopenmodal(true);
         physicianInformayionApi();

      }
   })

   const physicianInformayionApi = () => {
      let data = {
         "city": values.city,
         "facility_name": values.facility_name,
         "npi_number": values.npi_number,
         "first_name": values.first_name,
         "last_name": values.last_name,
         "state_licence_no": values.state_licence_no,
         "email_add": values.email_add,
         "street": values.street,
         "suite": values.suite,
         "zipcode": values.zipcode,
         "state": values.state,
         "state_license": values.state_license,
         "sub_speciality": values.sub_speciality,
         "Agree_To_Receive_Communications_IHEEZO__c": values.check,
      }
      
      
      fetch("https://api.iheezo.com/register.php", {
         method: "POST",
         headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
         },
         body: JSON.stringify(data)
      }).then((res) => {
         res.json().then((result) => {
            // setIsLoader(false);
            // setopenLoadermodal(false);
            // setIsSubmit(true);
            // setopenmodal(true);
         })
      })
   }

//console.log(values.sub_speciality,values.state,"s")
// console.log(values.street,"st")
// console.log(selectedItem,"si")
//console.log(itemList,"it")
   return (
      <>

         <div className="Header">
            <div className="topHeader">
               <div className="container">

                  <div className="t-h-menu">
                     <ul>
                        { /*<li><Link to="https://www.iheezo.com/">Important Safety Information</Link></li>*/}
                        <li><NavLink to="https://www.iheezo.com/prescribinginformation">Prescribing Information</NavLink></li>
                     </ul>
                  </div>
               </div>
            </div>
            <div className="bottomHeader">
               <div className="container">
                  <div className="t-h-logo">
                     <img src="img/header-logo.png" alt="" />
                  </div>
               </div>
            </div>
         </div>

         <section className="regForm">
            <div className="container">

               <div className="textCenter">
                  <h3 style={{ lineHeight: "39px", fontSize: "34px" }}>Thank you for your interest in evaluating IHEEZO<span className="sups">TM</span>. Please fill out the information below and we will reach out to you within 24 business hours.</h3>
               </div>

               {isSubmit ? <Modal isOpen={openmodal} onRequestClose={() => setopenmodal(false)}
                  shouldCloseOnOverlayClick={true}
                  style={{
                     overlay: {
                        zIndex: "500",
                        alignItems: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        position: "fixed"
                     },
                     content: {
                        width: "80%",
                        height: "auto",
                        minHeight: "230px",
                        borderRadius: "8px",
                        left: "50%",
                        top: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "50%",
                        transform: "translate(-50%,-50%)"
                     }
                  }}
               >
                  <div>
                     <div className='modal-head'>
                        <span>thank you!</span>
                        <button onClick={handleClose}><span>X</span></button>
                     </div>
                     <div className="textCenter inner-text" >
                        <h3>You have successfully registered for the IHEEZO Early Adopter Program. We have received your information and will be in touch within 24 hours.</h3>
                     </div>

                  </div>
               </Modal> : null}

               {isLoader ? <Modal isOpen={openLoadermodal} onRequestClose={() => setopenLoadermodal(false)}
                  shouldCloseOnOverlayClick={false}
                  style={{
                     overlay: {
                        zIndex: "500",
                        alignItems: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        position: "fixed"
                     },
                     content: {
                        width: "auto",
                        height: "auto",
                        minHeight: "40px",
                        borderRadius: "8px",
                        left: "50%",
                        top: "50%",
                        right: "auto",
                        bottom: "auto",
                        transform: "translate(-50%,-50%)"
                     }
                  }}
               >
                  <div>
                     <div className='modal-head'  style={{ borderBottom: "0", top:"0", padding:"0" }}>
                        <h5 style={{ color: "#293b97", fontWeight: "700", lineHeight: "0.6", marginBottom: "0"  }}>Please wait...</h5>
                     </div>
                  </div>
               </Modal> : null}
         

               <form onSubmit={handleSubmit}>
                  <div className="formBox">
                     <h3>Physician Information</h3>
                     <div className="formRow">
                        <div className="colSM6">
                           <div className="form-group name-group">
                              <div className="palceholder">
                                 <label>Physician First Name</label>
                                 <span className="star">*</span>
                              </div>
                              <input type="text" name='first_name' id="FirstName*"
                                 value={values.first_name}
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 className={errors.first_name && touched.first_name ? "form-control input-error" : "form-control"}
                              />
                           </div>
                           {errors.first_name && touched.first_name ?
                              <p style={{ color: "red", fontSize: "15px" }}>{errors.first_name}</p>
                              : null}
                        </div>
                        <div className="colSM6">
                           <div className="form-group name-group">
                              <div className="palceholder">
                                 <label>Physician Last Name</label>
                                 <span className="star">*</span>
                              </div>
                              <input type="text" name='last_name' id="LastName"
                                 value={values.last_name}
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 className={errors.last_name && touched.last_name ? "form-control input-error" : "form-control"}
                              />
                           </div>
                           {errors.last_name && touched.last_name ?
                              <p style={{ color: "red", fontSize: "15px" }}>{errors.last_name}</p>
                              : null}
                        </div>
                        <div className="colSM6">
                           <div className="form-group name-group">
                              <div className="palceholder">
                                 <label>NPI</label>
                                 <span className="star">*</span>
                              </div>
                              <input type="text" name='npi_number' id="NPI"
                                 value={values.npi_number}
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 className={errors.npi_number && touched.npi_number ? "form-control input-error" : "form-control"}
                              />
                           </div>
                           {errors.npi_number && touched.npi_number ?
                              <p style={{ color: "red", fontSize: "15px" }}>{errors.npi_number}</p>
                              : null}
                        </div>
                       
                        <div className="colSM6">
                           <div className="selectors">
                              <div className="selectorField" id="selectorField"></div>
                              {
                                 dropdown1?<div key="255" className='dropdownStar'><p key="555" className='dropdownStar_sub'></p></div>
                                 :<div key="3495" className='dropdownStar'><p key="3344" className='dropdownStar_sub'>*</p></div>
                              }
                              
                              <select name='state_license'
                                 onClick={()=>Setdropdown1(true)}
                                 value={values.state_license}
                                 onChange={handleChange}
                                 onBlur={handleBlur}        
                                 className={errors.state_license && touched.state_license ? "form-control input-error" : "form-control"}
                              >
                                 <option id="selectorText">Licensed State</option>

                                 {state.map((res) => {
                                    return (
                                       <>
                                          <option key={res}>{res}</option>
                                       </>
                                    )
                                 })}

                              </select>
                              {errors.state_license && touched.state_license ?
                                 <p style={{ color: "red", fontSize: "15px" }}>{errors.state_license}</p>
                                 : null}
                           </div>
                        </div>
                        <div className="colSM6">
                           <div className="form-group name-group">
                              <div className="palceholder">
                                 <label>State License Number</label>
                                 <span className="star">*</span>
                              </div>
                              <input type="text" name='state_licence_no' id="LicenseNumber"
                                 value={values.state_licence_no}
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 className={errors.state_licence_no && touched.state_licence_no ? "form-control input-error" : "form-control"}
                              />
                           </div>
                           {errors.state_licence_no && touched.state_licence_no ?
                              <p style={{ color: "red", fontSize: "15px" }}>{errors.state_licence_no}</p>
                              : null}
                        </div>
                        <div className="colSM6">
                           <div className="selectors">
                              <div className="selectorField" id="selectorField2">
                              </div>

                              {/* <div onClick={e => setIsDropDownVisible(!isDropDownVisiable)}>
                                 {selectedItem != null ? <div className="selectorField" id="selectorField2">
                                    <p id="selectorText2">{itemList[selectedItem].name}</p>
                                    <img src="img/chevron-down.svg" alt="" />
                                 </div> : <div className="selectorField" id="selectorField2">
                                    <p 
                                    value={values.sub_speciality}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.sub_speciality && touched.sub_speciality ? "input-error" : null}
                                    id="selectorText2">Sub-Specialty<span className="star">*</span></p>
                                    <img src="img/chevron-down.svg" alt="" />
                                 </div>}
                              </div>

                              {
                                 isDropDownVisiable ?
                                    <div className='item-holder'>
                                       {itemList.map((item, index) => (
                                          <div className='option' onClick={e => {
                                             setSelectedItem(index);
                                             setIsDropDownVisible(false);
                                          }
                                          }>
                                             {item.name}
                                          </div>
                                       ))
                                       }
                                    </div> : <></>

                              } */}
                              {
                                 dropdown2?<div key="23" className='dropdownStar2'><p key="323" className='dropdownStar_sub2'></p></div>
                                 :<div key="3234" className='dropdownStar2'><p key="3238" className='dropdownStar_sub2'>*</p></div>
                              }
                              <select
                           name='sub_speciality'
                           value={values.sub_speciality}
                           onChange={handleChange}
                           onBlur={handleBlur}
                           className={errors.sub_speciality && touched.sub_speciality?"form-control input-error":"form-control"}
                           onClick={()=>Setdropdown2(true)}
                           >
                              <option id="selectorText2">
                                 Sub-Specialty
                              </option>
                              <option>Cataract</option>
                              <option>Retina</option>
                              <option>Other</option>
                           </select>
                              {errors.sub_speciality && touched.sub_speciality ?
                                 <p style={{ color: "red", fontSize: "15px" }}>{errors.sub_speciality}</p>
                                 : null}

                           </div>
                        </div>

                        <div className="colSM12">
                           <div className="form-group name-group">
                              <div className="palceholder">
                                 <label name="email_add">Email Address</label>
                                 <span className="star">*</span>
                              </div>
                              <input type="text" name='email_add' id="email_add"
                                 value={values.email_add}
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 className={errors.email_add && touched.email_add ? "form-control input-error" : "form-control"}
                                 required="" />
                           </div>
                           {errors.email_add && touched.email_add ?
                              <p style={{ color: "red", fontSize: "15px" }}>{errors.email_add}</p>
                              : null}
                        </div>
                     </div>
                  </div>
                  <div className="formBox2">
                     <h3>Facility Information</h3>
                     <div className="formRow">
                        <div className="colSM12">
                           <div className="form-group name-group">
                              <div className="palceholder">
                                 <label htmlFor='Facility'>Facility Name</label>
                                 <span className="star">*</span>
                              </div>
                              <input type="text" name='facility_name' id="Facility"
                                 value={values.facility_name}
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 className={errors.facility_name && touched.facility_name ? "form-control input-error" : "form-control"}
                                 required="" />
                           </div>
                           {errors.facility_name && touched.facility_name ?
                              <p style={{ color: "red", fontSize: "15px" }}>{errors.facility_name}</p>
                              : null}
                        </div>
                        <div className="titleTexT">
                           <h4>Address</h4>
                        </div>
                        <div className="colSM70">
                           <div className="form-group name-group">
                              <div className="palceholder">
                                 <label>Street</label>
                                 <span className="star">*</span>
                              </div>
                              <input type="text" name='street' id="Street"
                                 value={values.street}
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 className={errors.street && touched.street ? "form-control input-error" : "form-control"}
                                 required="" />
                           </div>
                           {errors.street && touched.street ?
                              <p style={{ color: "red", fontSize: "15px" }}>{errors.street}</p>
                              : null}
                        </div>
                        <div className="colSM30">
                           <div className="form-group name-group">
                              <div className="palceholder">
                                 <label>Apt/Suite</label>
                                 
                              </div>
                              <input type="text" name='suite' className="form-control" id="Suite"
                                 value={values.suite}
                                 onChange={handleChange}
                                 // onBlur={handleBlur}
                                 // className={errors.suite && touched.suite ? "input-error" : null}
                                  />
                           </div>
                           {/* {errors.suite && touched.suite ?
                              <p style={{ color: "red", fontSize: "15px" }}>{errors.suite}</p>
                              : null} */}
                        </div>
                        <div className="colSM60">
                           <div className="form-group name-group">
                              <div className="palceholder">
                                 <label>City</label>
                                 <span className="star">*</span>
                              </div>
                              <input type="text" name='city' id="City"
                                 value={values.city}
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 className={errors.city && touched.city ? "form-control input-error" : "form-control"}
                                 required="" />
                           </div>
                           {errors.city && touched.city ?
                              <p style={{ color: "red", fontSize: "15px" }}>{errors.city}</p>
                              : null}
                        </div>
                        <div className="colSM30">
                           <div className="selectors">
                              {
                                 dropdown3?<div key="66" className='dropdownStar3'><p key="88" className='dropdownStar_sub3'></p></div>
                                 :<div key="77" className='dropdownStar3'><p key="99" className='dropdownStar_sub3'>*</p></div>
                              }
                              <select name='state'
                                 value={values.state}
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 className={errors.state && touched.state ? "form-control input-error" : "form-control "}
                                 onClick={()=>{Setdropdown3(true)}}
                              >
                                 <option id="selectorText3">State</option>
                                 {state.map((res) => {
                                    return (
                                       <>
                                          <option key={res}>{res}</option>
                                       </>
                                    )
                                 })}

                              </select>
                              {errors.state && touched.state ?
                                 <p style={{ color: "red", fontSize: "15px" }}>{errors.state}</p>
                                 : null}

                           </div>
                        </div>
                        <div className="colSM10">
                           <div className="form-group name-group">
                              <div className="palceholder">
                                 <label>Zipcode</label>
                                 <span className="star">*</span>
                              </div>
                              <input type="text" name='zipcode' id="Zipcode"
                                 value={values.zipcode}
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 className={errors.zipcode && touched.zipcode ? "form-control input-error" : "form-control"}
                                 required="" />
                           </div>
                           {errors.zipcode && touched.zipcode ?
                              <p style={{ color: "red", fontSize: "15px" }}>{errors.zipcode}</p>
                              : null}
                        </div>
                     </div>

                     <div className="required-fields">
                        <h6><span className="starts">*</span>Required field.</h6>
                        <div className="regFormsubmit">
                           {/* <div className="checkboxs checked">
                           <input className="checkmarks" type="chekbox" name="check"
                           value={values.check}
                           onChange={handleChange}
                           />
                           <span className="checkmarks"></span>
                           <p>By checking this box, I agree to receive communications from Harrow.</p>
                        </div> */}
                           <div className="checkboxs checked">
                              <input className="checkmarks" type="checkbox" name="check"
                                 value={values.check}
                                 onChange={handleChange}
                              />
                              {/* <span className="checkmarks"></span> */}
                              <p>By checking this box, I agree to receive communications from Harrow.</p>
                           </div>
                           <div className="checkboxs">
                              <p>Please <a href="https://www.iheezo.com/prescribinginformation.pdf" target="_blank"><b>CLICK HERE</b></a> for full prescribing information.</p>
                           </div>
                           {errors.check ?
                              <p style={{ color: "red", fontSize: "15px" }}>{errors.check}</p>
                              : null}
                           <div className="submit-btns2"><button type="submit">SUBMIT</button></div>
                        </div>
                     </div>
                  </div>
               </form>
            </div>
         </section>

         <div className="Footer">
            <div className="container">
               <div className="FooterPara">
                  <div className="foo-logo foo-logo-1">
                     <img src="img/harrow.png" alt="" />
                  </div>
               </div>
               <div className="FooterPara">
                  <p>IHEEZO, IHEEZO logo, and Harrow logo are trademarks are trademarks of Harrow IP, LLC. <br />© 2023 Harrow. All Rights Reserved.</p>
                  <p className="footerPlast" style={{paddingTop: "20px"}}>IZO-00030 03/23</p>
               </div>
            </div>
         </div>

         <div className="thankyou">
            <div className="container">
               <div className="thankyouBox">
                  <div className="thankyouInner">
                     <div className="closeX">
                        <img src="img/closeX.svg" alt="closeX" />
                     </div>
                     <h3>Thank You!</h3>
                     <p>You have successfully registered for the IHEEZO Early Adopter Program. We have <br />received your information and will be in touch within 24 hours. </p>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default Register;