import React from "react";

const Footer=()=>{
    return(
        <>
         <section className="home-s3" id="target-container">
         <div className="container">
            <div className="content-inner">
               <div className="para">
               <h3 style={{ color : "#293b97" }} >APPROVED USE </h3>
                  <p>IHEEZO is indicated for ocular surface anesthesia.  </p>
               </div>
               <div id="imp" className="para">
               <h3 style={{ color : "#293b97" }} >IMPORTANT SAFETY INFORMATION </h3>
                  <p>IHEEZO is contraindicated in patients with a history of hypersensitivity to any component of this preparation.</p>
               </div>
               <div className="para">
                  <p>IHEEZO should not be injected or intraocularly administered.  </p>
               </div>
               <div className="para">
                  <p>Patients should not touch the eye for at least 10 to 20 minutes after using anesthetic as accidental injuries can occur due to insensitivity of the eye. </p>
               </div>
               <div className="para">
                  <p>Prolonged use of a topical ocular anesthetic may produce permanent corneal opacification and ulceration with accompanying visual loss.  </p>
               </div>
               <div className="para">
                  <p>Do not touch the dropper tip to any surface as this may contaminate the gel.  </p>
               </div>
               <div className="para">
                  <p>IHEEZO is indicated for administration under the direct supervision of a healthcare provider. IHEEZO is not intended for patient self-administration.  </p>
               </div>
               <div className="para">
                  <p>The most common adverse reactions in studies following IHEEZO administration (incidence greater than or equal to 5%) were mydriasis, conjunctival hyperemia, and eye irritation.</p>
               </div>
               <div className="para">
                  <p>You are encouraged to report suspected adverse reactions to the FDA. Visit <b><u><a href="https://www.fda.gov/medwatch">www.fda.gov/medwatch</a></u></b>, or call <b><u><a href="tel:1-800-FDA-1088">1-800-FDA-1088</a></u></b>.  </p>
               </div>
               <div className="para">
                  <p>Please see the Full Prescribing Information for IHEEZO at <b><u><a href="https://www.iheezo.com/prescribinginformation.pdf" target="_blank" rel="noreferrer">www.iheezo.com/prescribinginformation.</a></u></b></p>
               </div>
               <div className="para">
               <p><b>References: 1. </b>IHEEZO. Prescribing information. Harrow IP, LLC; 2022. <b>2. </b>Data on File. Harrow IP, LLC; 2023. <b>3. </b>Chloroprocaine hydrochloride ophthalmic gel 3%. Pharmaceutical development. Sintetica; 2022. <b>4. </b>Han J. Rinella NT, Chao DL. Anesthesia for intravitreal injection: a systematic review. Clin Ophthalmol. 2020;14:543-540.</p>
               </div>
               <div className="para last-para">
                  <p>Trademarks referenced herein are held by their respective owners.</p>
               </div>
               {/* <div className="dropdown-btn"><img src="img/chevron-down-solid.png" alt=""/></div> */}
            </div>
         </div>
      </section>
      {/* <!-- ==============================FOOTER SECTION--> */}
      <footer>
         <div className="container">
            <div className="foo-inner-row">
               <div className="foo-inner-col-15">
                  <div className="foo-logo foo-logo-1">
                     <a href="https://www.harrow.com/"><img src="img/harrow.svg" alt=""/></a>
                  </div>
               </div>
               <div className="foo-inner-col-85">
                  <div className="foo-social">
                     <ul style={{display:"none"}}>
                     <li><a href="https://www.linkedin.com/company/harrow-health-inc/about/"><img src="img/linkedin.png" alt=""/></a></li>
                        {/* <li><a href="#"><img src="img/twit.png" alt=""/></a></li>
                        <li><a href="#"><img src="img/insta.png" alt=""/></a></li>
                        <li><a href="#"><img src="img/fb.png" alt=""/></a></li> */}
                     </ul>
                  </div>
                  <div className="foo-logo foo-logo-2">
                     <img src="img/harrow.svg" alt=""/>
                  </div>
                  <div className="foo-copyright">
                  <div className="pvc">
                     <ul>
                        <li><a href="https://www.harrow.com/privacy.html">Privacy Policy</a></li>
                        <li> <span className="lineborder">|</span></li>
                        <li><a href="https://www.harrow.com/cookies.html">Cookie Policy</a></li>
                        <li> <span className="lineborder">|</span></li>
                        <li><a href="/terms-of-use">Terms of Use</a></li>
                     </ul>
                  </div>
                     <p>IHEEZO, IHEEZO logo, and the Harrow logo are trademarks of Harrow IP, LLC <br/>©2024 Harrow. All Rights Reserved IZO-00030 06/24</p>
                  </div>
               </div>
            </div>
         </div>
      </footer>

      {/*  popup footer */}
      <div className="paras2 removeTextSize" id="box">
            <div className="container" id="flotsCont">
               <div className="show-less-btn">
                  <button className="showBTN btn-s-l">SHOW MORE <span className="dropDownBtn"></span></button>
                  <button style={{display:"none"}} className="lessBTN btn-s-l">SHOW LESS <span className="dropDownBtn"></span></button>
               </div>
               <div className="scrolls">
               <div className="para">
                  <h3 style={{ color : "#293b97" }} >APPROVED USE</h3>
                  <p>IHEEZO is indicated for ocular surface anesthesia.  </p>
               </div>

               <div id="imp" className="para">
               <h3 style={{ color : "#293b97" }} >IMPORTANT SAFETY INFORMATION </h3>
                  <p>IHEEZO is contraindicated in patients with a history of hypersensitivity to any component of this preparation.</p>
               </div>

               
               <div className="flots">
               <div className="para">
                  <p>IHEEZO should not be injected or intraocularly administered.  </p>
               </div>
               <div className="para">
                  <p>Patients should not touch the eye for at least 10 to 20 minutes after using anesthetic as accidental injuries can occur due to insensitivity of the eye. </p>
               </div>
               <div className="para">
                  <p>Prolonged use of a topical ocular anesthetic may produce permanent corneal opacification and ulceration with accompanying visual loss.  </p>
               </div>
               <div className="para">
                  <p>Do not touch the dropper tip to any surface as this may contaminate the gel.  </p>
               </div>
               <div className="para">
                  <p>IHEEZO is indicated for administration under the direct supervision of a healthcare provider. IHEEZO is not intended for patient self-administration.  </p>
               </div>
               <div className="para">
                  <p>The most common adverse reactions in studies following IHEEZO administration (incidence greater than or equal to 5%) were mydriasis, conjunctival hyperemia, and eye irritation.</p>
               </div>
               <div className="para">
                  <p>You are encouraged to report suspected adverse reactions to the FDA. Visit <b><u><a href="https://www.fda.gov/medwatch">www.fda.gov/medwatch</a></u></b>, or call <b><u><a href="tel:1-800-FDA-1088">1-800-FDA-1088</a></u></b>.  </p>
               </div>
               <div className="para">
                  <p>Please see the Full Prescribing Information for IHEEZO at <b><u><a href="https://www.iheezo.com/prescribinginformation.pdf" target="_blank" rel="noreferrer">www.iheezo.com/prescribinginformation.</a></u></b></p>
               </div>
               <div className="para">
               <p><b>References: 1. </b>IHEEZO. Prescribing information. Harrow IP, LLC; 2022. <b>2. </b>Data on File. Harrow IP, LLC; 2023. <b>3. </b>Chloroprocaine hydrochloride ophthalmic gel 3%. Pharmaceutical development. Sintetica; 2022. <b>4. </b>Han J. Rinella NT, Chao DL. Anesthesia for intravitreal injection: a systematic review. Clin Ophthalmol. 2020;14:543-540.</p>
               </div>
               <div className="para last-para">
                  <p>Trademarks referenced herein are held by their respective owners.</p>
               </div>
               </div>
               <div className="clearfix"></div>
               </div>
            </div>
         </div> 
        </>
    )
}

export default Footer