import React from "react";
import { BrowserRouter,Routes,Route } from "react-router-dom";
import Register from "./components/Register";
import About from "./pages/About-IHEEZO";
import Anesthesia from "./pages/anesthesia";
import Contact from "./pages/contact";
import Secure from "./pages/index-secure";
import Order from "./pages/order";
import Reimbursement from "./pages/reimbursement";
import TermsOfUse from "./pages/terms-of-use";
import Footer from "../src/common/Footer-about";

const App=()=>{
   return(
      <BrowserRouter>
      <Routes>
      <Route path="/" element={<Secure/>} />
         <Route path="/about" element={<About/>} />
         <Route path="contact" element={<Contact/>} />
         <Route path="/Reimbursement" element={<Reimbursement/>} />
         <Route path="/order" element={<Order/>} />
         <Route path="/dosinginformation" element={<Anesthesia/>} />
         <Route path="/starterprogram" element={<Register/>} />
         // <Route path="/earlyadopter" element={<Register/>} />
         <Route path="/terms-of-use" element={<TermsOfUse/>} />
         <Route path="/feedback" element={<TermsOfUse/>} />
         <Route path="/prescribinginformation" element={<TermsOfUse/>} />
      </Routes>
      {/* <Register/> */}
      </BrowserRouter>
      
   )
}
export default App








