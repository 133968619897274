import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer-no-references";
import { Helmet } from "react-helmet-async";
 import { useFormik } from 'formik';
import { ContactSchema } from "../schemas";
import '../App.css';
import state from "../state";
import Modal from 'react-modal'
 const Reimbursement=()=>{


   const [title, setTitle] = useState("Contact Us");

   useEffect(() => {
      document.title = title;
    }, [title]);
   
   const [isLoader, setIsLoader] = useState(false);
   const [openLoadermodal, setopenLoadermodal] = useState(false)
   
   const [isSubmit, setIsSubmit] = useState(false);
   const [openmodal, setopenmodal] = useState(false)
   const [tnc, settnc] = useState(false)

   const initialValues = {
      first_name: "",
      last_name: "",
      email: "",
      phone_no: "",
      states: "",
      roll_no: "",
      facility: "",
      message: "",
      sub_specialty: "",
      check: false
   }
   const handleClose = () => {
      window.location.reload();
      setopenmodal(false)
   }

   const { values, errors, handleBlur, touched, handleChange, handleSubmit } = useFormik({
      initialValues: initialValues,
      validationSchema: ContactSchema,
      onSubmit: (values, action) => {
         // setIsLoader(true);
         // setopenLoadermodal(true);
         setIsSubmit(true);
         setopenmodal(true);

         window.dataLayer = window.dataLayer || [];
         window.dataLayer.push({
                  'event' : 'contactus',
         });
         
         contactApi();
      }
   })

   const contactApi = () => {
      let data = {
         "first_name": values.first_name,
         "last_name": values.last_name,
         "email": values.email,
         "phone_no": values.phone_no,
         "message": values.message,
         "facility": values.facility,
         "roll_no": values.roll_no,
         "states": values.states,
         "sub_specialty": values.sub_specialty
      }
      fetch("https://api.iheezo.com/contact.php", {
         method: "POST",
         headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
         },
         body: JSON.stringify(data)
      }).then((res) => {
         res.json().then((result) => {
            // setIsLoader(false);
            // setopenLoadermodal(false);
            // setIsSubmit(true);
            // setopenmodal(true);
         })
      });
   }

    return(
        <>
        
      <Helmet>
         <title>IHEEZO Reimbursement</title>
         <meta name="description" value="IHEEZO™ is reimbursed per milligram. Therefore, providers should always use 1 mg as the billing unit and bill for 800 units when IHEEZO is utilized." />
         <meta name="keywords" value="iheezo, chloroprocaine, topical anesthetic, ocular anesthetic, sterile topical anesthetic, ophthalmic anesthetic, ocular anesthetic reimbursement" />
      </Helmet>

        <section className="pagetitle pagetitleCONTACT">
         <div className="container">
            <div className="pagetitle-text">
               <h1>No-Hassle Reimbursement Support</h1>
            </div>
            <div className="pg-icon-2"><img src="img/pg-icon-2.png" alt=""/></div>
            <div className="pg-icon-3"><img src="img/pg-icon-3.png" alt=""/></div>
         </div>
      </section>
       {/* <!-- ==============================PAGETITLE SECTION--> */}
       <section className="Reimbursement ab-sec1 ">
            <div className="container" style={{position:"relative"}}>
               <div className="Reimbursement-row">
                  <div className="Reimbursement-col-L">
                  <h2 className="h2l">IHEEZO is the only FDA-approved topical ocular anesthetic with transitional pass-through and a permanent J-code: <div style={{color:"#e8bf65", display:"inline"}}>J-2403</div></h2>
                  
                 

              
                     <div className=" ">
                     <h2 style={ {marginBottom:"20px"}}>Dedicated Reimbursement Support</h2>
                     <div className="para">
                        <p>When you order IHEEZO, you will be assigned a dedicated Reimbursement Business Manager. They can help support your coding, billing, and claims submission questions.<span className="sup">*</span>
                        </p>
                     </div>
                  
                  </div>
                  </div>
                  <div className="Reimbursement-col-R">
                  <div className="Reimbursement-box
                                 Reimbursement-box-disnone" style={ {background:"#293b97",paddingBottom:"20px",paddingLeft:"20px",paddingRight:"20px",marginBottom:"15px"}}>
                     <div className="Reimbursement-box-a">
                        <a style={ {marginTop:"25px",marginBottom:"5px",fontSize:"27px", color:"#fff"}} href="#">Reimbursement resources</a>
                     </div>

                     <div className="Reimbursement-box-pdf">
                        <a href="/img/IZO-00035_Reimbursement_Guide_+PI_web.pdf" target="_blank">
                        {/* <div className="pdf-icon">
                           <img src="img/pdf-icon.png" alt="" />
                        </div> */}
                        <p style={ {color:"#293b97", textAlign:"center", width:"100%"}}>Download Reimbursement Guide</p>
                        </a>
                     </div>

                     <div className="Reimbursement-box-pdf">
                        <a href="/img/BillingandCodingGuide.pdf" target="_blank">
                        {/* <div className="pdf-icon">
                           <img src="img/pdf-icon.png" alt="" />
                        </div> */}
                        <p style={ {color:"#293b97", textAlign:"center", width:"100%"}}>Download Billing & Coding Guide</p>
                        </a>
                     </div>

                  </div>
                  
                  </div>

                  
               </div>
               <div>
           
               
         {isSubmit ? <Modal isOpen={openmodal} onRequestClose={() => setopenmodal(false)}
         shouldCloseOnOverlayClick={true}
         style={{
            overlay: {
               zIndex: "500",
               alignItems: "center",
               backgroundColor: "rgba(0, 0, 0, 0.8)",
               position: "fixed"
            },
            content: {
               width: "80%",
               height: "auto",
               minHeight: "180px",
               borderRadius: "8px",
               left: "50%",
               top: "50%",
               right: "auto",
               bottom: "auto",
               marginRight: "50%",
               transform: "translate(-50%,-50%)"
            }
         }}
      >
         <div>
            <div className='modal-head'  style={{ borderBottom: "0" }}>
               <h3 style={{ color: "#293b97", fontWeight: "700", lineHeight: "1"  }}>Thank you for your message.</h3>
               <p style={{color: "#293b97"}}>We'll be in contact soon.</p>
               <button onClick={handleClose}><span>X</span></button>
            </div>
            <div className="textCenter inner-text" >
            <p style={{ color: "#293b97", lineHeight:"1.2"}}>Looking to talk to someone about IHEEZO™ now? <br/>You can also call us at <b><a style={{ color: "#293b97"}} href="tel:1-833-442-7769">1-833-442-7769 (1-833-4HARROW)</a></b>.</p>
               {/* <h3>We have received your information and will be in contact within 24 hours.</h3> */}
            </div>

         </div>
      </Modal> : null}

      {isLoader ? <Modal isOpen={openLoadermodal} onRequestClose={() => setopenLoadermodal(false)}
         shouldCloseOnOverlayClick={false}
         style={{
            overlay: {
               zIndex: "500",
               alignItems: "center",
               backgroundColor: "rgba(0, 0, 0, 0.8)",
               position: "fixed"
            },
            content: {
               width: "auto",
               height: "auto",
               minHeight: "40px",
               borderRadius: "8px",
               left: "50%",
               top: "50%",
               right: "auto",
               bottom: "auto",
               transform: "translate(-50%,-50%)"
            }
         }}
      >
         <div>
            <div className='modal-head'  style={{ borderBottom: "0", top:"0", padding:"0" }}>
               <h5 style={{ color: "#293b97", fontWeight: "700", lineHeight: "0.6", marginBottom: "0"  }}>Please wait...</h5>
            </div>
         </div>
      </Modal> : null}
      
      {false ? <div className="container">
         <div className="message">
         <h2>Thank you for your message</h2>
         <p style={{marginTop:"10px"}}>We'll be in contact soon</p>
         <p>Looking to talk to someone about IHEEZO™ now? <br/>You can also call us at <b><a style={{ color: "#293b97" }} href="tel:1-833-442-7769">1-833-442-7769 (1-833-4HARROW)</a></b>.</p>
         </div>
      </div> :
         <section className="anesthesia contactus" style={{position:"relative", zIndex:"0"}}>
            <div className="">
               <div className="contact-header">
               <h4 style={{color: "rgb(41, 59, 151)", paddingTop:"15px"}}>
               For help with Reimbursement questions please complete the form below and someone will respond within 24 business hours.
               </h4>
               </div>

               <div className="form" id="contact-form" onSubmit={handleSubmit}>
                  <form>
                     <div className="form-rows">
                        <div className="form-col-L">
                           <div className="form-input-rows">
                              <div className="form-input-col6" key="1">
                                 <input type="text" name="first_name" placeholder="First Name*"
                                    value={values.first_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.first_name && touched.first_name ? "input-error" : null}
                                 />
                                 {errors.first_name && touched.first_name ?
                                    <p style={{ color: "red", fontSize: "15px" }}>{errors.first_name}</p>
                                    : null}
                              </div>
                              <div className="form-input-col6" key="2">
                                 <input type="text" name="last_name" placeholder="Last Name*"
                                    value={values.last_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.last_name && touched.last_name ? "input-error" : null}
                                 />
                                 {errors.last_name && touched.last_name ?
                                    <p style={{ color: "red", fontSize: "15px" }}>{errors.last_name}</p>
                                    : null}
                              </div>
                              <div className="form-input-col6" key="3">
                                 <input type="text" name="email" placeholder="Email Address*"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.email && touched.email ? "input-error" : null}
                                 />
                                 {errors.email && touched.email ?
                                    <p style={{ color: "red", fontSize: "15px" }}>{errors.email}</p>
                                    : null}
                              </div>
                              <div className="form-input-col6" key="4">
                                 <input type="text" name="phone_no" placeholder="Phone Number"
                                    value={values.phone_no.replace(/\D/g,'')}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.phone_no && touched.phone_no ? "input-error" : null}
                                 />
                                 {errors.phone_no && touched.phone_no ?
                                    <p style={{ color: "red", fontSize: "15px" }}>{errors.phone_no}</p>
                                    : null}
                              </div>
                              <div className="form-input-col6" key="5">
                                 <select name="states" required="Required"
                                    value={values.states}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.states && touched.states ? "input-error" : null}
                                 >
                                    <option value="1">State*</option>
                                    {state.map((res) => {
                                       return (
                                          <option key={res}>{res}</option>
                                       )
                                    })}
                                 </select>
                                 {errors.states && touched.states ?
                                    <p style={{ color: "red", fontSize: "15px" }}>{errors.states}</p>
                                    : null}
                              </div>
                              <div className="form-input-col6" key="6">
                                 <select name="roll_no" required="Required"
                                    value={values.roll_no}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.roll_no && touched.roll_no ? "input-error" : null}
                                 >
                                    <option value="1">Role*</option>
                                    <option>Healthcare Professional</option>
                                    <option>Medical Staff</option>
                                    <option>Other</option>
                                 </select>
                                 {errors.roll_no && touched.roll_no ?
                                    <p style={{ color: "red", fontSize: "15px" }}>{errors.roll_no}</p>
                                    : null}
                              </div>
                              <div className="form-input-col6" key="7">
                                 <input type="text" name="facility" placeholder="Facility Name"
                                    value={values.facility}
                                    onChange={handleChange}
                                 />
                              </div>
                              <div className="form-input-col6" key="8">
                                 <select name="sub_specialty" required="Required"
                                    value={values.sub_specialty}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={errors.sub_specialty && touched.sub_specialty ? "input-error" : null}
                                 >
                                    <option value="1">Sub-specialty*</option>
                                    <option>Cataract & Refractive</option>
                                    <option>Retina</option>
                                    <option>Anesthesia</option>
                                    <option>Other</option>
                                 </select>
                                 {errors.sub_specialty && touched.sub_specialty ?
                                    <p style={{ color: "red", fontSize: "15px" }}>{errors.sub_specialty}</p>
                                    : null}
                              </div>
                           </div>
                        </div>
                        <div className="form-col-R">
                           <textarea name="message" placeholder="Message"
                              value={values.message}
                              onChange={handleChange}
                           ></textarea>
                        </div>
                     </div>
                     <div className="required-fields">
                        <h6>*Required field.</h6>
                        <div className="form-row-submit">
                           <div className="checkboxs checked">
                              <input type="checkbox" name="check"
                                 value={values.check}
                                 onChange={handleChange} checked
                              />
                              {/* <span className="checkmarks"></span> */}
                              <p>I authorize Harrow, Inc., to send me emails regarding IHEEZO and other informational materials. I understand that I may revoke this authorization and choose not to receive information from Harrow by clicking the “unsubscribe” link provided in emails
                                 I receive from Harrow. I have read and understand Harrow&apos;s <u><a style={{ color: "#000", fontFamily: "Roboto Condensed", fontWeight: "400"}} href="https://www.harrow.com/privacy.html" target="_blank">Privacy Notice</a></u>.</p>
                           </div>
                           <div className="submit-btns button-div" style={{ width: "200px"}}>
                              <input type="submit" value="Submit" />
                           </div>
                           
                        </div>
                     </div>
                  </form>

               </div>
            </div>
         </section>}
               </div>
           

<div >
<p style={{fontSize:"12px", paddingTop:"30px", lineHeight:"18px"}}>*Individual plan coverage, payment, policies, and procedures vary and should be confirmed by the facility. Harrow does not guarantee reimbursement.</p>
</div>
            </div>
            </section>
                     {/* <!--
                     ==============================APPROVED USE SECTION--> */} <Footer/> </> ) }
                     export default Reimbursement
