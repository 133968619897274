import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import Header from "../common/Header";
import Footer from "../common/Footer-no-references";
import "./order.css"
import { Helmet } from "react-helmet-async";

const Order = () => {

   return (
      <>

      <Helmet>
         <title>Order IHEEZO</title>
         <meta name="description" value="IHEEZO (chloroprocaine) is a low-viscosity gel supplied in a sterile, single-use unit. It contains 24 mg of chloroprocaine hydrochloride per unit (800 mg). Each package ordered consists of one unit." />
         <meta name="keywords" value="Order IHEEZO, chloroprocaine for ocular anesthesia, ocular surface anesthesia, cataract surgery anesthetic, ophthalmic anesthetic" />
      </Helmet>

         <section className="pagetitle pagetitleCONTACT">
            <div className="container">
               <div className="pagetitle-text">
                  <h1>Order IHEEZO</h1>
               </div>
               <div className="pg-icon-1 d-b-mob"><img src="img/pg-icon-1.png" alt="" /></div>
               <div className="pg-icon-2"><img src="img/pg-icon-2.png" alt="" /></div>
               <div className="pg-icon-3"><img src="img/pg-icon-3.png" alt="" /></div>
            </div>
         </section>
         {/* <!-- ==============================PAGETITLE SECTION--> */}
         <section className="ab-sec1 order">
            <div className="container">
               <div className="order-row">
                  <div className="order-col-L">
                     <h2>Ordering Information</h2>
                     <div className="para">
                        <p>IHEEZO™ is a low-viscosity gel supplied in a sterile, single-use unit. It contains 24 mg of chloroprocaine hydrochloride per unit (800 mg). Each package ordered consists of one unit.</p>
                     </div>
                     <div className="para">
                        <p>Every step of the ordering and reimbursement process for IHEEZO is supported by a dedicated Reimbursement Business Manager, whom you will be connected with when you choose to use IHEEZO.</p>
                     </div>
                  
                  </div>
                  <div className="order-col-R">
                     <div className="order-box">
                        <p>For more information about IHEEZO, contact us at<a href="tel:1-833-442-7769"><b> 1-833-442-7769 (1-833-4HARROW) </b></a>from 8 am to 8 pm ET or email us at <a href="mailto:info@iheezo.com"><b>info@iheezo.com.</b></a></p>
                     </div>
                  </div>
               </div>
               <div>
                        <table>
                        <tbody>
                           <tr>
                              <th>NDC #</th>
                              <th>Unit Size</th>
                              <th>Purchase Quantity</th>
                           </tr>
                           <tr>
                              <td><b>10-Digit:</b> 82667-300-01</td>
                              <td>800 mg</td>
                              <td>1 unit</td>
                           </tr>
                           <tr>
                              <td><b>11-Digit:</b> 82667-0300-01*</td>
                              <td>800 mg</td>
                              <td>1 unit</td>
                           </tr>
                           </tbody>
                           
                        </table>
                        <p style={{fontSize:"14px", fontWeight:"600", letterSpacing:"0px"}}>*11-digit code is the preferred NDC code for reimbursement</p>
                     </div>
                     <div style={{marginTop:"15px"}}>
                         <table>
                         <tbody>
                           <tr>
                              <th className="text-left">Wholesaler/Distributor</th>
                              <th>Item Number</th>
                              <th>Contact Phone Number</th>
                           </tr>
                           {/* <tr>
                              <td className="text-left">AmerisourceBergen</td>
                              <td>#10278824</td>
                              <td><a href="tel:8007466273" style={{color:"#000"}}>(800) 746-6273</a></td>
                           </tr> */}
                           <tr>
                              <td className="text-left">Besse</td>
                              <td>#10280055</td>
                              <td><a href="tel:8005432111" style={{color:"#000"}}>(800) 543-2111</a></td>
                           </tr>
                           <tr>
                              <td className="text-left">Cardinal Specialty (SPD)</td>
                              <td>#5851738</td>
                              <td><a href="tel:8774533972" style={{color:"#000"}}>(877) 453-3972</a></td>
                           </tr>
                           <tr>
                              <td className="text-left">McKesson Medical Surgical</td>
                              <td>#1228615</td>
                              <td><a href="tel:8555712100" style={{color:"#000"}}>(855) 571-2100</a></td>
                           </tr>
                           <tr>
                              <td className="text-left">Metro Medical</td>
                              <td>#300101</td>
                              <td><a href="tel:8007682002" style={{color:"#000"}}>(800) 768-2002</a></td>
                           </tr>
                           {/* <tr>
                              <td className="text-left">Wholesaler/Distributor’s Name</td>
                              <td>#xxxxxxxx</td>
                              <td>(xxx) xxx-xxxx</td>
                           </tr> */}
                           {/* <tr>
                              <td className="text-left">McKesson</td>
                              <td style={{color:"#ed2c4e"}}>[xxxxxxx]</td>
                              <td style={{color:"#ed2c4e"}}>[xxx-xxx-xxxx]</td>
                           </tr> */}
                           </tbody>
                        </table>
                     </div>
            </div>
         </section>
         {/* <!-- ==============================APPROVED USE SECTION--> */}
         <Footer />
      </>
   )
}
export default Order
